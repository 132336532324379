import { Container, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import React, { useState } from 'react';
import Api from '../../../Api';
import { ApiRoutes } from '../../../ApiRoutes';
import { IUser, RoleType, SubscriptionType } from '../../../models/User';

/* eslint-disable i18next/no-literal-string */

const RECORDS_PER_PAGE = 20;

interface IPageMeta {
  readonly page: number;
  readonly take: number;
  readonly itemCount: number;
  readonly pageCount: number;
  readonly hasPreviousPage: boolean;
  readonly hasNextPage: boolean;
}

interface IUserWithDates
  extends Omit<IUser, 'createdAt' | 'subscriptionExpiresAt'> {
  readonly createdAt: Date | null;
  readonly subscriptionExpiresAt: Date | null;
}

const UserAdminPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [sortStatus, setSortStatus] = useState<
    DataTableSortStatus<IUserWithDates>
  >({
    columnAccessor: 'id',
    direction: 'asc',
  });

  const { data: pageData, isLoading } = useQuery({
    queryKey: [
      'users',
      `p${page}`,
      `s${sortStatus.columnAccessor}-${sortStatus.direction}`,
    ],

    queryFn: () =>
      Api.ky
        .get(
          `${ApiRoutes.Users}?page=${page}&take=${RECORDS_PER_PAGE}&sort=${sortStatus.columnAccessor}&order=${sortStatus.direction.toUpperCase()}`,
        )
        .json<{ data: IUser[]; meta: IPageMeta }>()
        .then((pageData): { data: IUserWithDates[]; meta: IPageMeta } => ({
          ...pageData,
          data: pageData.data.map(
            (user): IUserWithDates => ({
              ...user,
              createdAt: user.createdAt ? new Date(user.createdAt) : null,
              subscriptionExpiresAt: user.subscriptionExpiresAt
                ? new Date(user.subscriptionExpiresAt)
                : null,
            }),
          ),
        })),
  });

  return (
    <Container>
      <Title order={2} mb="xl">
        User Admin
      </Title>

      <DataTable<IUserWithDates>
        fetching={isLoading}
        withTableBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        records={pageData?.data || []}
        totalRecords={pageData?.meta.itemCount}
        page={page}
        recordsPerPage={RECORDS_PER_PAGE}
        onPageChange={setPage}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        columns={[
          { accessor: 'id', sortable: true, width: 260 },
          { accessor: 'email', sortable: true },
          {
            accessor: 'subscriptionType',
            title: 'Sub',
            render: ({ subscriptionType }) => {
              const color =
                subscriptionType === SubscriptionType.PRO
                  ? 'teal'
                  : subscriptionType === SubscriptionType.TRIAL
                    ? 'orange'
                    : 'gray';
              return (
                <Text
                  style={{ fontWeight: 700, textTransform: 'uppercase' }}
                  color={color}
                >
                  {subscriptionType}
                </Text>
              );
            },
          },
          {
            accessor: 'subscriptionExpiresAt',
            render: ({ subscriptionExpiresAt }) =>
              subscriptionExpiresAt
                ? dayjs(subscriptionExpiresAt).format('YYYY-MM-DD')
                : null,
            sortable: true,
            title: 'Sub Exp',
          },
          {
            accessor: 'role',
            sortable: true,
            render: ({ role }) => {
              const color = role === RoleType.ADMIN ? 'red' : 'gray';
              return (
                <Text
                  style={{ fontWeight: 700, textTransform: 'uppercase' }}
                  color={color}
                >
                  {role}
                </Text>
              );
            },
          },
          {
            accessor: 'paymentProvider',
            title: 'Pay',
          },
          {
            accessor: 'createdAt',
            render: ({ createdAt }) =>
              dayjs(createdAt).format('YYYY-MM-DD HH:mm'),
            sortable: true,
            title: 'Created',
          },
        ]}
      />
    </Container>
  );
};

export default UserAdminPage;
