import { useQuery } from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import type { IUserProfile } from './Profile';

export enum RoleType {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum SubscriptionType {
  FREE = 'free',
  TRIAL = 'trial',
  PRO = 'pro',
  ULTIMATE = 'ultimate',
}

export interface IUser {
  id: string;
  email: string;
  username?: string;
  subscriptionType: SubscriptionType;
  subscriptionExpiresAt: string;
  paymentProvider: string;
  toursDone: string[];
  language: string;
  role: RoleType;
  isActive?: boolean;
  profile?: IUserProfile;
  updatedAt: string;
  createdAt: string;
}

export type UserId = IUser['id'];

export const QUERY_KEY_USER = ['user'];

export const useUserQuery = () =>
  useQuery({
    queryKey: QUERY_KEY_USER,
    queryFn: () => Api.ky.get(ApiRoutes.Me).json<IUser>(),
    staleTime: 60 * 60 * 1000,
  });

export function isSubscriptionExpired(user?: IUser) {
  return Boolean(
    user?.subscriptionExpiresAt &&
      new Date(user.subscriptionExpiresAt) < new Date(),
  );
}
