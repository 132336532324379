import { Button, Container, Paper, TextInput, Title } from '@mantine/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';
import { AppRouteURL } from '../../AppRouteURL';
import { getLogger } from '../../services/Logger';

const log = getLogger('AdminPage');

/* eslint-disable i18next/no-literal-string */

const AdminPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const handleDeleteUser = () => {
    setDeleteError(null);

    Api.ky
      .delete(ApiRoutes.Users, { json: { email } })
      .then(() => {
        alert('User deleted successfully');
        setEmail('');
      })
      .catch((error) => {
        log.error(error);
        if (error instanceof Error || typeof error === 'string') {
          setDeleteError(error.toString());
        }
      });
  };

  return (
    <Container>
      <Title order={2}>Administration</Title>

      <Paper m="xl" p="xl" shadow="lg">
        <Button component={Link} to={AppRouteURL.adminUser}>
          User Management
        </Button>
      </Paper>

      <Paper m="xl" p="xl" shadow="lg">
        <Title order={4}>Delete User</Title>
        <TextInput
          label="User Email"
          placeholder="example@example.com"
          value={email}
          onChange={(event) => {
            setEmail(event.currentTarget.value);
          }}
          required
        />
        <Button
          mt="md"
          color="red"
          onClick={handleDeleteUser}
          disabled={!email}
        >
          Delete User
        </Button>
        {deleteError && <div style={{ color: 'red' }}>{deleteError}</div>}
      </Paper>
    </Container>
  );
};

export default AdminPage;
